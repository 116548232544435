<template>
	<a-card class="mr-3 mb-3">
		<a-statistic :value="percentage" suffix="%" :value-style="{ color: color }">
			<template #title>
				<h3 :style="{ color: color }">{{ title }}</h3>
			</template>
			<template #prefix>
				<h4 class="mr-3" :style="{ color: color }">
					{{ getHumanReadableNumber(value) }}
					{{ currency === 'CAD' ? '(CAD)' : '' }}
				</h4>
			</template>
		</a-statistic>
	</a-card>
</template>

<script>
export default {
	props: {
		title: String,
		value: Number,
		percentage: Number,
		color: String,
		currency: String,
	},
	methods: {
		getHumanReadableNumber(number) {
			return (number || number == 0) && `$${number.toLocaleString(
				"en-US",
				{
					useGrouping: true,
					minimumFractionDigits: 2,
					maximumFractionDigits: 2,
				}
			)}` || 0
		},
	},
};
</script>
